const link = {
  send: "envoyer",
  contactUs: "contactez-nous",
  faqs: "faqs",
  aboutUs: "a-propos-de-nous",
  resetPassword: "resetPassword",
  resetPasswords: "resetPasswords",
  login: "connexion",
  register: "inscription",
  transferInit: "transfert/init",
  connectMX: "connecterMX",
  conditionsTerms: "conditions-generales",
  policy: "politique",
  cookies: "cookies",
  career: "carriere",
  careerJobOffer: "offre-de-carriere",
};

export default link;
